<template>
  <div
    v-if="show"
    ref="confimration_modal"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm"
    id="confirmModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <div class="modal__header mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <span class="modal__header__title text--capital">
              Filter Transactions By Date Range
            </span>
            <a
              class="modal__close modal__close--white d-flex align-items-center justify-content-between"
              @click="$emit('close')"
            >
              <span class="text--capital text--regular"> close </span>
              <ion-icon name="close-outline"></ion-icon>
            </a>
          </div>
          <span class="modal__header__subtitle text--capital"> </span>
        </div>
        <div class="modal__content">
          <div class="modal__body">
            <div class="account_item_head">
              <p class="account_item_head_text text--white">
                select date range
              </p>
            </div>

            <div class="form__item mt-5" style="display: flex; width: 100%">
              <div class="mr-2" style="width: 50%">
                <label class="form__label text--black text--capital mb-3"
                  >start date</label
                >
                <input
                  type="date"
                  class="input form__input form__input--lg r-font"
                  placeholder="Enter value"
                  :max="dateLimit"
                  @change="clearEndDate"
                  v-model="startDate"
                />
              </div>

              <div class="ml-2" style="width: 50%">
                <label class="form__label text--black text--capital mb-3"
                  >end date</label
                >
                <input
                  type="date"
                  class="input form__input form__input--lg r-font"
                  placeholder="Enter value"
                  :min="startDate"
                  :max="dateLimit"
                  @change="checkEndDate"
                  v-model="endDate"
                />
              </div>
            </div>

            <div class="d-flex mt-5">
              <button
                class="button form__button form__button--sm w-50 mr-2"
                @click="getFilterResult"
              >
                {{ isLoading ? "loading..." : "search" }}
              </button>
              <button
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2 btn-danger text-dark"
                @click="$emit('close')"
              >
                close
              </button>
              <button
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="clear"
              >
                clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "SalesReportDateFilter",
  props: {
    show: Boolean,
    close: Function,
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      isLoading: false,
      dateLimit: moment().format("YYYY-MM-DD"),
    };
  },
  methods: {
    getFilterResult() {
      if ((this.startDate, this.endDate)) {
        this.$emit("getFilterResult", {
          startDate: this.startDate,
          endDate: this.endDate,
        });
      } else {
        this.$emit("statementAlert", {
          type: "warning",
          message: "Start date and End date are required.",
        });
      }
    },
    clearEndDate() {
      if (moment(this.startDate).isAfter(this.dateLimit)) {
        this.$emit("statementAlert", {
          type: "warning",
          message: "Start date cannot be greater than today's date.",
        });
        this.startDate = "";
      }
    },
    checkEndDate() {
      if (
        !moment(this.endDate).isBetween(
          this.startDate,
          this.dateLimit,
          null,
          "[]"
        )
      ) {
        this.$emit("statementAlert", {
          type: "warning",
          message:
            "End date must be greater than start date and less than today's date.",
        });
        this.endDate = "";
      }
    },
    clear() {
      this.startDate = "";
      this.endDate = "";
      this.$emit("clear");
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
};
</script>

<style scoped>
.overlap {
  word-wrap: break-word;
  width: 90%;
}
</style>
