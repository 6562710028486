<template>
  <div
    class="tab-pane fade"
    id="pills-failedTransaction"
    role="tabpanel"
    aria-labelledby="pills-failedTransaction-tab"
  >
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <div class="box">
      <a
        id="excel__download"
        v-if="userCanExportToPDF"
        class="btn download-box"
        @click="downloadCSVFile"
      >
        Download Templates
      </a>

      <button
        class="button button--black text--capital text-white text--small d-flex align-items-center"
        type="button"
        @click="showDateFilterModal"
      >
        <span class="filter_text"> Filter by date range</span>
      </button>
    </div>

    <hr style="border: 1.3px solid #e5e5e5" />
    <div class="ma-table">
      <div class="ma-thead">
        <div v-for="(text, i) in thead_texts" :key="i" class="thead-child">
          {{ text }}
        </div>
      </div>

      <hr style="border: 0.6px solid #e5e5e5; width: 300%" />
      <div v-if="setLoading">
        <div class="d-flex align-items-center justify-content-center p5">
          <div
            class="spinner-border"
            style="width: 3rem; height: 3rem"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <span class="text--black text--600 text--medium d-inline-flex ml-4"
            >Please wait</span
          >
        </div>
      </div>

      <div
        v-else-if="
          allFailedTransactionsData.transactions &&
          allFailedTransactionsData.transactions.data &&
          allFailedTransactionsData.transactions.data.length > 0
        "
      >
        <div
          v-for="(item, i) in allFailedTransactionsData.transactions.data"
          :key="i"
        >
          <div class="ma-tbody">
            <div style="word-wrap: break-word" class="tbody-child">
              {{ item.data_set_name.replace(/_/g, " ") }}
            </div>
            <div class="tbody-child">
              {{ item.source_account_number }}
            </div>
            <div class="tbody-child">
              {{ getProductName(item.product_id) }}
            </div>
            <div class="tbody-child">
              {{ item.destination_account_number }}
            </div>
            <div class="tbody-child">
              {{ item.document_number }}
            </div>
            <div class="tbody-child">
              {{ addCommas(item.amount) }}
            </div>
            <div>
              <div class="tbody-child text-success" v-if="item.status">
                True
              </div>
              <div v-else class="tbody-child text-danger">False</div>
            </div>
            <div class="tbody-child">
              {{ item.is_deleted }}
            </div>
            <div class="tbody-child" style="word-break: break-word">
              {{
                item.delete_reason ? item.delete_reason.replace(/_/g, " ") : ""
              }}
            </div>
            <div class="tbody-child" style="word-break: break-word">
              {{ item.description.replace(/_/g, " ") }}
            </div>
            <div class="tbody-child">
              {{ item.posting_channel }}
            </div>
            <div class="tbody-child" style="word-break: break-word">
              {{
                item.error_message
                  ? item.error_message.replace(/[^a-zA-Z 0-9]+/g, " ")
                  : ""
              }}
            </div>
            <div class="tbody-child">
              {{ item.date_created | moment }}
            </div>
            <div class="tbody-child">
              {{ item.date_modified | moment }}
            </div>
            <div class="tbody-child">
              {{ item.date_deleted | moment }}
            </div>
          </div>

          <hr style="border: 0.6px solid #e5e5e5; width: 285%" />
        </div>
      </div>

      <div v-else>
        <div
          class="d-flex flex-column align-items-center justify-content-center p5"
        >
          <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

          <span class="d-block text--danger text--600 text--medium mt-3 mb-5">
            No data available</span
          >
        </div>
      </div>
    </div>
    <Pagination
      v-if="
        allFailedTransactionsData.transactions &&
        allFailedTransactionsData.transactions.data &&
        allFailedTransactionsData.transactions.data.length > 0
      "
      :allItems="allFailedTransactionsData.transactions"
      :size="parameters.pageSize"
      @update-created-task="changeLength"
      @change-page-index="changeCurrentPage"
    />

    <DateFilter
      :show="showFilter"
      @close="showDateFilterModal"
      @statementAlert="imageAlert"
      @clear="clearDateFilterModal"
      @getFilterResult="getFilterResult"
    />
  </div>
</template>
<script>
import moment from "moment";
import Papa from "papaparse";
import DateFilter from "./Components/DateFilter.vue";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";

export default {
  name: "FailedTrans",
  props: ["allFailedTransactionsData", "loading", "allProducts"],
  data() {
    return {
      thead_texts: [
        "Dataset Name",
        "Source Acc. Number",
        "Product Name",
        "Destination Acc. Number",
        "Document Number",
        "Amount",
        "Status",
        "Deleted",
        "Reason Deleted",
        "Description",
        "Posting Channel",
        "Error Message",
        "Date Created",
        "Date Modified",
        "Date Deleted",
      ],
      parameters: {
        pageIndex: 1,
        pageSize: 20,
        creationDateFrom: moment(new Date()).startOf("date").add(1, "hours"),
        creationDateTo: moment(new Date()).endOf("date").add(1, "hours"),
        transactionIds: [],
        sortColumn: null,
        sortDesending: true,
      },
      showFilter: false,
      alert: {
        status: "",
        title: "",
        description: "",
        show: false,
      },
    };
  },
  components: {
    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
    DateFilter,
  },
  methods: {
    downloadCSVFile() {
      const jsonData = this.allFailedTransactionsData.transactions.data;
      // .map((a) => ({
      //   savingsAccountName: a.savingsAccountName,
      //   accountFirstName: a.accountFirstName,
      //   accountLastName: a.accountLastName,
      //   accountStatus: a.accountStatus,
      //   balance: a.balance,
      // }));

      let blob = new Blob([Papa.unparse(jsonData)], {
        type: "text/csv;charset=utf-8;",
      });
      let link = document.createElement("a");
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "failed-transactions.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.getGuidCSVFile();
    },

    getGuidCSVFile() {
      const jsonData = this.allFailedTransactionsData.transactionIds.map(
        (a) => ({
          Request_ID: a,
        })
      );

      let blob = new Blob([Papa.unparse(jsonData)], {
        type: "text/csv;charset=utf-8;",
      });
      let link = document.createElement("a");
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "guid_file.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    getFilterResult({ startDate, endDate }) {
      this.parameters.creationDateFrom = startDate;
      this.parameters.creationDateTo = endDate;

      this.$emit("getFailedTransData", this.parameters);
      this.showDateFilterModal();
    },

    showDateFilterModal() {
      this.showFilter = !this.showFilter;
    },

    clearDateFilterModal() {
      this.showFilter = false;
      this.parameters.creationDateFrom = moment(new Date())
        .startOf("date")
        .add(1, "hours");
      this.parameters.creationDateTo = moment(new Date())
        .endOf("date")
        .add(1, "hours");
      this.$emit("getFailedTransData", this.parameters);
    },

    imageAlert({ type, message }) {
      if (type === "error") {
        this.alertError(message);
      }
      if (type === "success") {
        this.alertSuccess(message);
      }
      if (type === "warning") {
        this.alertWarning(message);
      }
    },

    getProductName(id) {
      let prodName = "";

      if (id) {
        prodName = this.allProducts.find(
          (item) => item.id === id || item.loanProductKey === id
        )?.productName;
      }

      return prodName;
    },

    addCommas(amount) {
      let currency = `\u20A6`;
      if (amount) {
        let fixedAmount = amount.toFixed(2);
        return `${currency}${fixedAmount
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
      } else {
        return `${currency}0.00`;
      }
    },

    changeCurrentPage(pages = null, pageSize) {
      if (pages === "next") {
        this.parameters.pageIndex++;
        this.parameters.pageSize = pageSize;
        this.$emit("getFailedTransData", this.parameters);
      } else if (pages === "previous") {
        this.parameters.pageIndex--;
        this.parameters.pageSize = pageSize;
        this.$emit("getFailedTransData", this.parameters);
      } else if (pages !== null) {
        this.parameters.pageIndex = pages;
        this.parameters.pageSize = pageSize;
        this.$emit("getFailedTransData", this.parameters);
      }
    },

    changeLength(value) {
      this.parameters.pageSize = value;
      this.$emit("getFailedTransData", this.parameters);
    },

    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    setLoading() {
      return this.loading;
    },
    userCanExportToPDF() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },
  },
};
</script>

<style scoped>
.head-text {
  font-weight: 600;
  font-size: 1.8rem;
  font-family: "Montserrat-semibold";
  line-height: 32px;
  color: #1e1f20;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.box {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.download-box {
  width: 185px;
  height: 40px;
  background: #e9e7e7;
  border-radius: 5px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  text-align: center;
}
.btn-black {
  width: 185px;
  height: 40px;
  background: #000000;
  border-radius: 5px;
  border: 1px solid #000000;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.dash-box {
  width: 75%;
  /* border: 1px dashed #c4c4c4; */
  box-sizing: border-box;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.hid-box {
  width: 25%;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.thead-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #9596a2;
  width: 150px;
  /* text-align: flex-start; */
}
.tbody-child {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
  width: 150px;
  align-items: center;
  /* text-align: flex-start; */
  padding-top: 10px;
  padding-bottom: 10px;
}
.ma-thead,
.ma-tbody {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(15, 1fr) !important;
  /* display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px; */
}
.ma-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: scroll;
}
.action {
  width: 95px;
  height: 35px;
  background: #e9e7e7;
  border-radius: 5px;
  border: 1px dashed #e9e7e7;
  font-weight: 500;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.action-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.action-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.disable-btn {
  background: #e9e7e7;
  width: 185px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e9e7e7;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 37px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
input[type="file"] {
  display: none;
}
</style>
