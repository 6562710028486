var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane fade",attrs:{"id":"pills-failedTransaction","role":"tabpanel","aria-labelledby":"pills-failedTransaction-tab"}},[_c('div',{staticClass:"toaster show"},[(_vm.alert.show)?_c('div',{staticClass:"toaster__container",class:{
        'toaster--success': _vm.alert.status === 'success',
        'toaster--error': _vm.alert.status === 'fail',
        'toaster--warn': _vm.alert.status === 'warn',
      }},[_c('div',{staticClass:"text-right"},[_c('span',{staticClass:"fa fa-times",on:{"click":function($event){_vm.alert.show = false}}})]),_c('div',[_c('span',{staticClass:"d-block text--small text--600 text--white"},[_vm._v(_vm._s(_vm.alert.title))]),_c('span',{staticClass:"d-block text--tiny text--400 text--white"},[_vm._v(_vm._s(_vm.alert.description))])])]):_vm._e()]),_c('div',{staticClass:"box"},[(_vm.userCanExportToPDF)?_c('a',{staticClass:"btn download-box",attrs:{"id":"excel__download"},on:{"click":_vm.downloadCSVFile}},[_vm._v(" Download Templates ")]):_vm._e(),_c('button',{staticClass:"button button--black text--capital text-white text--small d-flex align-items-center",attrs:{"type":"button"},on:{"click":_vm.showDateFilterModal}},[_c('span',{staticClass:"filter_text"},[_vm._v(" Filter by date range")])])]),_c('hr',{staticStyle:{"border":"1.3px solid #e5e5e5"}}),_c('div',{staticClass:"ma-table"},[_c('div',{staticClass:"ma-thead"},_vm._l((_vm.thead_texts),function(text,i){return _c('div',{key:i,staticClass:"thead-child"},[_vm._v(" "+_vm._s(text)+" ")])}),0),_c('hr',{staticStyle:{"border":"0.6px solid #e5e5e5","width":"300%"}}),(_vm.setLoading)?_c('div',[_vm._m(0)]):(
        _vm.allFailedTransactionsData.transactions &&
        _vm.allFailedTransactionsData.transactions.data &&
        _vm.allFailedTransactionsData.transactions.data.length > 0
      )?_c('div',_vm._l((_vm.allFailedTransactionsData.transactions.data),function(item,i){return _c('div',{key:i},[_c('div',{staticClass:"ma-tbody"},[_c('div',{staticClass:"tbody-child",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(item.data_set_name.replace(/_/g, " "))+" ")]),_c('div',{staticClass:"tbody-child"},[_vm._v(" "+_vm._s(item.source_account_number)+" ")]),_c('div',{staticClass:"tbody-child"},[_vm._v(" "+_vm._s(_vm.getProductName(item.product_id))+" ")]),_c('div',{staticClass:"tbody-child"},[_vm._v(" "+_vm._s(item.destination_account_number)+" ")]),_c('div',{staticClass:"tbody-child"},[_vm._v(" "+_vm._s(item.document_number)+" ")]),_c('div',{staticClass:"tbody-child"},[_vm._v(" "+_vm._s(_vm.addCommas(item.amount))+" ")]),_c('div',[(item.status)?_c('div',{staticClass:"tbody-child text-success"},[_vm._v(" True ")]):_c('div',{staticClass:"tbody-child text-danger"},[_vm._v("False")])]),_c('div',{staticClass:"tbody-child"},[_vm._v(" "+_vm._s(item.is_deleted)+" ")]),_c('div',{staticClass:"tbody-child",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(item.delete_reason ? item.delete_reason.replace(/_/g, " ") : "")+" ")]),_c('div',{staticClass:"tbody-child",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(item.description.replace(/_/g, " "))+" ")]),_c('div',{staticClass:"tbody-child"},[_vm._v(" "+_vm._s(item.posting_channel)+" ")]),_c('div',{staticClass:"tbody-child",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(item.error_message ? item.error_message.replace(/[^a-zA-Z 0-9]+/g, " ") : "")+" ")]),_c('div',{staticClass:"tbody-child"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.date_created))+" ")]),_c('div',{staticClass:"tbody-child"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.date_modified))+" ")]),_c('div',{staticClass:"tbody-child"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.date_deleted))+" ")])]),_c('hr',{staticStyle:{"border":"0.6px solid #e5e5e5","width":"285%"}})])}),0):_c('div',[_vm._m(1)])]),(
      _vm.allFailedTransactionsData.transactions &&
      _vm.allFailedTransactionsData.transactions.data &&
      _vm.allFailedTransactionsData.transactions.data.length > 0
    )?_c('Pagination',{attrs:{"allItems":_vm.allFailedTransactionsData.transactions,"size":_vm.parameters.pageSize},on:{"update-created-task":_vm.changeLength,"change-page-index":_vm.changeCurrentPage}}):_vm._e(),_c('DateFilter',{attrs:{"show":_vm.showFilter},on:{"close":_vm.showDateFilterModal,"statementAlert":_vm.imageAlert,"clear":_vm.clearDateFilterModal,"getFilterResult":_vm.getFilterResult}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center justify-content-center p5"},[_c('div',{staticClass:"spinner-border",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]),_c('span',{staticClass:"text--black text--600 text--medium d-inline-flex ml-4"},[_vm._v("Please wait")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center p5"},[_c('img',{attrs:{"src":require("@/assets/img/empty.svg"),"alt":"delete","srcset":""}}),_c('span',{staticClass:"d-block text--danger text--600 text--medium mt-3 mb-5"},[_vm._v(" No data available")])])
}]

export { render, staticRenderFns }